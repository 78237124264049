import axios from "axios";
import moment from "moment";

const getFearAndGreed = async () => {
  const fearAndGreed = await axios.get(
    `${process.env.VUE_APP_FUNCTIONS_URL}/getFearAndGreed`
  );

  return fearAndGreed;
};

const getCryptoPrices = async (cmcIds, cgIds, conversions) => {
  const promises = [];

  for (const convert of conversions) {
    promises.push(
      await axios.post(`${process.env.VUE_APP_FUNCTIONS_URL}/getCryptoPrices`, {
        cmcIds,
        cgIds,
        convert,
      })
    );
  }

  let results;

  try {
    results = await Promise.all(promises);
  } catch (err) {
    console.error("Error getting crypto prices", { error: err });
  }

  console.log(results);

  const prices = {};

  if (results) {
    for (const result of results) {
      const currency = result.data.curr;
      const CG_DATA = result.data.CG_DATA;
      const CMC_DATA = result.data.CMC_DATA;
      prices[currency] = {};
      if (CMC_DATA.status !== "error") {
        for (const coin of CMC_DATA.data) {
          const symbol = coin.symbol.toUpperCase();
          prices[currency][symbol] = {};
          prices[currency][symbol].price = coin.quote[currency].price;
          prices[currency][symbol].changes = {
            d: coin.quote[currency].percent_change_24h,
            w: coin.quote[currency].percent_change_7d,
            m: coin.quote[currency].percent_change_30d,
            q: coin.quote[currency].percent_change_90d,
            y: 0,
            ath: 0,
          };
        }
      }
      if (CG_DATA.status !== "error") {
        for (const coin of CG_DATA.data) {
          const symbol = coin.symbol.toUpperCase();
          prices[currency][symbol].changes.percent_change_ath =
            coin.atl_change_percentage;
          prices[currency][symbol].changes.percent_change_1y =
            coin.price_change_percentage_1y_in_currency;
        }
      }
    }
  }

  return prices;
};

const processPortfolio = async (portfolio) => {
  const original = JSON.parse(JSON.stringify(portfolio));

  // console.log(original);

  const conversions = ["USD", "CAD"];

  const cmcIds1 = portfolio.cryptos.map((item) => item.cmcId);
  const cgIds1 = portfolio.cryptos.map((item) => item.cgId);
  const cmcIds2 = portfolio.jamcoin.yesterday.cryptos.map((item) => item.cmcId);
  const cgIds2 = portfolio.jamcoin.yesterday.cryptos.map((item) => item.cgId);

  const cmcIds = [...new Set([...cmcIds1, ...cmcIds2])];
  const cgIds = [...new Set([...cgIds1, ...cgIds2])];

  const cryptoPrices = await getCryptoPrices(cmcIds, cgIds, conversions);

  portfolio.cashValueFmt = {
    CAD: format(portfolio.cashValue.CAD),
    USD: format(portfolio.cashValue.USD),
    // ETH: format(
    //   portfolio.cashValue.USD / cryptoPrices.USD[1027].quote.USD.price,
    //   "ETH"
    // ),
    // BTC: format(
    //   portfolio.cashValue.USD / cryptoPrices.USD[1].quote.USD.price,
    //   "BTC"
    // ),
  };

  portfolio.bookValue = {
    CAD: portfolio.bookValue.CAD,
    USD: portfolio.bookValue.USD,
    // ETH: portfolio.bookValue.USD / cryptoPrices.USD[1027].quote.USD.price,
    // BTC: portfolio.bookValue.USD / cryptoPrices.USD[1].quote.USD.price,
  };

  portfolio.bookValueFmt = {
    CAD: format(portfolio.bookValue.CAD),
    USD: format(portfolio.bookValue.USD),
    // ETH: format(
    //   portfolio.bookValue.USD / cryptoPrices.USD[1027].quote.USD.price,
    //   "ETH"
    // ),
    // BTC: format(
    //   portfolio.bookValue.USD / cryptoPrices.USD[1].quote.USD.price,
    //   "BTC"
    // ),
  };

  portfolio.totalValueFmt = {
    CAD: 0,
    USD: 0,
    // ETH: 0,
    // BTC: 0,
  };

  let equityHoldings, alphaData;
  let currencyHoldings;

  portfolio.totalMarketValue = {
    CAD: 0,
    USD: 0,
    // ETH: 0,
    // BTC: 0,
  };

  portfolio.totalMarketValueFmt = {
    CAD: "",
    USD: "",
    // ETH: "",
    // BTC: "",
  };

  const audit = {};

  const userHoldings = [];

  for (const holding of portfolio.cryptos) {
    if (holding.symbol === "CHT") {
      break;
    }
    if (!cryptoPrices["USD"][holding.symbol]) {
      console.log("Missing price data for", holding.symbol);
      continue;
    }
    holding.amountFmt = toFixed(holding.amount);
    for (const curr of conversions) {
      holding[curr] = {};

      holding.src = `https://s2.coinmarketcap.com/static/img/coins/64x64/${holding.cmcId}.png`;

      holding[curr].price = cryptoPrices[curr][holding.symbol].price;

      holding[curr].value = holding[curr].price * holding.amount;

      const changes = cryptoPrices[curr][holding.symbol].changes;

      holding[curr].priceFmt = format(holding[curr].price, curr);

      holding[curr].valueFmt = format(holding[curr].value, curr);

      portfolio.totalMarketValue[curr] += holding[curr].value;

      holding[curr].dVal = ((changes.d / 100) * holding[curr].value).toFixed(2);
      holding[curr].wVal = ((changes.w / 100) * holding[curr].value).toFixed(2);
      holding[curr].mVal = ((changes.m / 100) * holding[curr].value).toFixed(2);
      holding[curr].qVal = ((changes.q / 100) * holding[curr].value).toFixed(2);

      holding[curr].d = changes.d.toFixed(2);
      holding[curr].w = changes.w.toFixed(1);
      holding[curr].m = changes.m.toFixed(1);

      if (changes.q) {
        holding[curr].q = changes.q.toFixed(1);
      } else {
        holding[curr].q = 0;
      }
      if (changes.y) {
        holding[curr].y = changes.y.toFixed(1);
      } else {
        holding[curr].y = 0;
      }

      holding[curr].ath = changes.ath.toFixed(1);

      if (curr !== "USD" && curr !== "CAD") {
        holding[curr].avgpp =
          holding.avgpp["USD"] /
          cryptoPrices["USD"][holding.cmcId].quote["USD"].price;
        holding[curr].cost = holding[curr].avgpp * holding.amount;
      } else {
        holding[curr].cost = holding.cost[curr];
        holding[curr].avgpp = holding.avgpp[curr];
      }

      holding[curr].avgppFmt = format(holding[curr].avgpp, curr);
      holding[curr].p_l = holding[curr].value - holding[curr].cost;
      holding[curr].p_lFmt = format(holding[curr].p_l, curr);
      holding[curr].p_l_perc = (
        (holding["USD"].p_l / holding["USD"].cost) *
        100
      ).toFixed(1);
    }
    userHoldings.push(holding);
  }

  // console.log({ userHoldings });

  const table = [];

  for (const item of userHoldings) {
    const record = {
      symbol: item.symbol,
      amount: item.amount,
      price: item.USD.price,
      value: item.USD.value,
    };
    table.push(record);
  }

  portfolio.p_l = {};
  portfolio.p_lFmt = {};

  for (const curr of conversions) {
    portfolio.totalValue[curr] =
      portfolio.totalMarketValue[curr] + portfolio.cashValue[curr];
    portfolio.totalValueFmt[curr] = format(portfolio.totalValue[curr], curr);
    portfolio.totalMarketValueFmt[curr] = format(
      portfolio.totalMarketValue[curr],
      curr
    );
    if (curr !== "CAD" && curr !== "USD") {
      portfolio.p_l["USD"] =
        portfolio.totalValue["USD"] - portfolio.bookValue["USD"];
      portfolio.p_lFmt["USD"] = format(
        portfolio.totalValue["USD"] - portfolio.bookValue["USD"],
        "USD"
      );
    } else {
      portfolio.p_l[curr] =
        portfolio.totalValue[curr] - portfolio.bookValue[curr];
      portfolio.p_lFmt[curr] = format(
        portfolio.totalValue[curr] - portfolio.bookValue[curr],
        curr
      );
    }
  }

  portfolio.p_l_perc = (
    (portfolio.p_l["USD"] / portfolio.bookValue["USD"]) *
    100
  ).toFixed(1);

  // Calculate share of portfolio and weighted performance, starting with cash share
  const cashShare = portfolio.cashValue.USD / portfolio.totalValue.USD;
  let totalShare = cashShare;
  let performanceTotal = totalShare;

  for (const holding of userHoldings) {
    holding.share = holding["USD"].value / portfolio.totalValue["USD"];
    totalShare += holding.share;
    holding.shareFmt = (holding.share * 100).toFixed(2);
    performanceTotal += holding.share * holding.USD.price;
  }

  // Try something else

  performanceTotal = portfolio.totalValue.USD;

  // console.log({
  //   totalShare: totalShare,
  //   performanceTotal: performanceTotal,
  // });

  const jamcoin = {
    src: "/assets/jamcoin.png",
    coin: "JAM",
    symbol: "JAM",
    amount: "",
    amountFmt: "",
    share: totalShare - cashShare,
    shareFmt: ((totalShare - cashShare) * 100).toFixed(2),
    history: portfolio.jamcoin.history,
  };

  // Calculate performance

  // Calculate total value 24h ago

  let cryptoValueYesterday = 0;
  const cashValueYesterday = portfolio.jamcoin.yesterday.cashValue.USD;
  const holdingsYesterday = [];

  for (const holding of portfolio.jamcoin.yesterday.cryptos) {
    if (holding.symbol === "CHT") {
      break;
    }
    const now = portfolio.cryptos.find((item) => item.cmcId === holding.cmcId);
    if (now.USD) {
      const perc_24h = parseFloat(now.USD.d);
      const price_24 = now.USD.price - now.USD.price * (perc_24h / 100);
      const value_24 = holding.amount * price_24;
      cryptoValueYesterday += value_24;
    }
  }

  const totalValueYesterday = cryptoValueYesterday + cashValueYesterday;

  jamcoin.history.d = {
    totalValue: totalValueYesterday,
    date: portfolio.jamcoin.yesterday.balanceDate,
    cashValue: portfolio.jamcoin.yesterday.cashValue.USD,
    marketValue: cryptoValueYesterday,
    holdings: holdingsYesterday,
  };

  for (const [period, values] of Object.entries(jamcoin.history)) {
    const currentValue = portfolio.totalValue.USD;
    const periodValue = values.totalValue;
    // console.log(period, `Now: ${currentValue}`, `Then: ${periodValue}`);
    const change = (portfolio.totalValue.USD - periodValue) / periodValue;
    portfolio.jamcoin.history[period].change = change * 100;
  }

  for (const curr of conversions) {
    jamcoin[curr] = {
      price: "",
      value: portfolio.totalMarketValue[curr],
      valueFmt: portfolio.totalMarketValueFmt[curr],
      cost: portfolio.bookValue[curr],
      costFmt: portfolio.bookValueFmt[curr],
      p_l_perc: portfolio.p_l_perc,
      p_lFmt: portfolio.p_lFmt[curr],
      dVal: (
        (jamcoin.history.d.change / 100) *
        portfolio.totalMarketValue[curr]
      ).toFixed(2),
      wVal: (
        (jamcoin.history.w.change / 100) *
        portfolio.totalMarketValue[curr]
      ).toFixed(2),
      mVal: (
        (jamcoin.history.m.change / 100) *
        portfolio.totalMarketValue[curr]
      ).toFixed(2),
      qVal: (
        (jamcoin.history.q.change / 100) *
        portfolio.totalMarketValue[curr]
      ).toFixed(2),
      yVal: (
        (jamcoin.history.y.change / 100) *
        portfolio.totalMarketValue[curr]
      ).toFixed(2),
      athVal: portfolio.ath[curr].toFixed(0),
      d: jamcoin.history.d.change.toFixed(1),
      w: jamcoin.history.w.change.toFixed(1),
      m: jamcoin.history.m.change.toFixed(1),
      q: jamcoin.history.q.change.toFixed(1),
      y: jamcoin.history.y.change.toFixed(1),
      ath: jamcoin.history.ath.change.toFixed(1),
    };
  }

  userHoldings.push(jamcoin);

  // const filteredHoldings = userHoldings.filter(
  //   (holding) => holding.share > 0.0025
  // );

  userHoldings.sort((a, b) => {
    return b.USD.value - a.USD.value;
  });

  portfolio.holdings = userHoldings;

  // console.log(portfolio);

  return portfolio;
};

// if ("equities" in portfolio) {
//   equityHoldings = portfolio.equities;
//   let stats = [];
//   stats = await getEquityStats(equityHoldings);
//   console.log(stats);
//   let conversionRate;
//   for (const holding of equityHoldings) {
//     if (holding.market === "US") {
//       conversionRate = convertTo[preferredCurrency];
//     } else {
//       conversionRate = 1;
//     }
//     console.log(holding, conversionRate);
//     const priceStats = stats.find((stat) => stat.symbol === holding.symbol);
//     holding.coin = holding.coin.replace(".", "");
//     holding.symbol = holding.coin;
//     if (holding.cmc_id) {
//       holding.src = `https://s2.coinmarketcap.com/static/img/coins/64x64/${holding.cmc_id}.png`;
//     }
//     holding.price = priceStats.price * conversionRate;
//     holding.cost = holding.avgpp * holding.amount * conversionRate;
//     holding.value = holding.amount * holding.price;
//     holding.d = (priceStats.dayChange * 100).toFixed(2);
//     holding.w = (priceStats.weekChange * 100).toFixed(2);
//     holding.m = (priceStats.monthChange * 100).toFixed(2);
//     holding.q = (priceStats.quarterChange * 100).toFixed(2);
//     holding.history = {
//       d: {
//         diff: holding.value * (1 - priceStats.dayChange),
//       },
//       w: {
//         diff: holding.value * (1 - priceStats.weekChange),
//       },
//       m: {
//         diff: holding.value * (1 - priceStats.monthChange),
//       },
//       q: {
//         diff: holding.value * (1 - priceStats.quarterChange),
//       },
//     };
//     totalMarketValue += holding.value;
//     portfolio.totalBookValue += holding.cost;
//     holding.priceFmt = format(holding.price);
//     holding.costFmt = format(holding.avgpp * holding.amount);
//     holding.valueFmt = format(holding.value);
//     holding.p_l_perc = (
//       ((holding.value - holding.cost) / holding.cost) *
//       100
//     ).toFixed(1);
//     holding.p_l = holding.value - holding.cost;
//     holding.p_lFmt = format(holding.p_l);
//     holding.avgppFmt = format(holding.avgpp);
//     holding.amountFmt = holding.amount.toFixed(3);
//     userHoldings.push(holding);
//   }
// }

const getEquityStats = async (equities) => {
  const url = `${process.env.VUE_APP_FUNCTIONS_URL}/getEquityPrices/`;
  const marketData = await axios.post(url, equities);
  const data = [];
  console.log(marketData);
  return;
  for (const item of alpha_response.data) {
    const today = moment(item["Meta Data"]["3. Last Refreshed"]).format(
      "YYYY-MM-DD"
    );
    const currentPrice = parseFloat(
      item["Time Series (Daily)"][today]["4. close"]
    );

    const day = getWeekDay(
      moment(today).subtract(1, "days").format("YYYY-MM-DD")
    );

    let dayPrice, dayChange;

    if (day in item["Time Series (Daily)"]) {
      dayPrice = parseFloat(item["Time Series (Daily)"][day]["4. close"]);
      dayChange = (currentPrice - dayPrice) / dayPrice;
    } else {
      dayPrice = currentPrice;
      dayChange = 0;
    }

    const week = getWeekDay(
      moment(today).subtract(6, "days").format("YYYY-MM-DD")
    );
    const weekPrice = parseFloat(item["Time Series (Daily)"][week]["4. close"]);
    const weekChange = (currentPrice - weekPrice) / weekPrice;

    const month = getWeekDay(
      moment(today).subtract(30, "days").format("YYYY-MM-DD")
    );
    const monthPrice = parseFloat(
      item["Time Series (Daily)"][month]["4. close"]
    );
    const monthChange = (currentPrice - monthPrice) / monthPrice;

    const quarter = getWeekDay(
      moment(today).subtract(90, "days").format("YYYY-MM-DD")
    );
    const quarterPrice = parseFloat(
      item["Time Series (Daily)"][quarter]["4. close"]
    );
    const quarterChange = (currentPrice - quarterPrice) / quarterPrice;

    const obj = {
      symbol: item["Meta Data"]["2. Symbol"],
      price: currentPrice,
      dayChange: dayChange,
      weekChange: weekChange,
      monthChange: monthChange,
      quarterChange: quarterChange,
    };

    data.push(obj);
  }
  return data;
};

const toFixed = (num) => {
  const wholeNums = beforeDecimal(num);
  const decimals = Math.max(0, 4 - wholeNums);
  return num.toFixed(decimals);
};

function beforeDecimal(num) {
  if (Number.isInteger(num)) {
    return 1;
  }

  return num.toString().split(".")[0].length;
}

const format = (value) => {
  let formatter;
  if (beforeDecimal(value) > 3) {
    formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(value);
  } else {
    formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(value);
  }
};

const getWeekDay = (date) => {
  const day = moment(date).day();
  if (day === 0) {
    return moment(date).subtract(2, "days").format("YYYY-MM-DD");
  }
  if (day === 6) {
    return moment(date).subtract(1, "days").format("YYYY-MM-DD");
  }
  return date;
};

export default {
  processPortfolio,
  getFearAndGreed,
};
