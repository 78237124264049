import { createStore } from "vuex";
import firebase from "./firebase";
import moment from "moment";

export const store = createStore({
  debug: true,
  state() {
    return {
      userList: [],
      selectedUser: "",
      currentUser: {},
      portfolio: {},
      holdings: {},
      loading: true,
      deposits: [],
      journal: [],
      preferredCurrency: "USD",
      fgi: "",
      filtered: true,
      sort: "p_l_perc",
      sortDesc: true,
    };
  },
  getters: {
    getUserIdByCode: (state) => (code) => {
      const userList = JSON.parse(JSON.stringify(state.userList));
      const user = userList.find((user) => user.name === code);
      return user.uid;
    },
    getHoldings: (state) => () => {
      const s = state.sort;
      const curr = state.preferredCurrency;
      let holdings = state.portfolio.holdings;

      if (state.filtered) {
        holdings = holdings.filter((holding) => holding.share > 0.0025);
      }

      if (state.sortDesc) {
        holdings.sort((a, b) => {
          if (s === "share") {
            return b[s] - a[s];
          }
          return b[curr][s] - a[curr][s];
        });
      } else {
        holdings.sort((a, b) => {
          if (s === "share") {
            return a[s] - b[s];
          }
          return a[curr][s] - b[curr][s];
        });
      }
      return holdings;
    },
    getJournalEntry: (state) => (id) => {
      let entry = {
        created: null,
      };
      const entries = state.journal.filter((entry) => entry.id === id);
      if (entries.length > 0) {
        entry = entries[0];
        entry.created = moment(entry.created.toDate()).toISOString();
        console.log("001: ", entry);
      }
      console.log(entry);
      return entry;
    },
  },
  actions: {
    setSelectedUser({ commit }, user) {
      commit("setSelectedUser", user);
    },
    setUserlist({ commit }, userList) {
      commit("setUserList", userList);
    },
    setCurrentUser({ commit }, currentUser) {
      commit("setCurrentUser", currentUser);
    },
    setJournal({ commit }, journal) {
      commit("setJournal", journal);
    },
    setFiltered({ commit }, val) {
      commit("setFiltered", val);
    },
    setSort({ commit }, val) {
      commit("setSort", val);
    },
    setSortDesc({ commit }, val) {
      commit("setSortDesc", val);
    },
    setFearAndGreed({ commit }, data) {
      const fgi = {
        lastUpdate: moment(data.lastUpdate.humanDate).format("YYYY-MM-DD"),
        records: [],
      };

      for (const [time, values] of Object.entries(data.fgi)) {
        if (time !== "oneYearAgo") {
          let timeText;
          switch (time) {
            case "now":
              timeText = "Now";
              break;
            case "previousClose":
              timeText = "Yesterday";
              break;
            case "oneWeekAgo":
              timeText = "Last Week";
              break;
            case "oneMonthAgo":
              timeText = "Last Month";
              break;
          }
          const record = {
            time: time,
            timeText: timeText,
            value: values.value,
            class: values.valueText.replace(" ", ""),
            valueText: values.valueText,
          };
          fgi.records.push(record);
        }
      }
      commit("setFearAndGreed", fgi);
    },
    async setPortfolio({ commit }, user) {
      commit("setLoading", true);
      const portfolio = await firebase.getPortfolio(user.uid);
      commit("setPortfolio", portfolio);
    },
    setPreferredCurrency({ commit }, curr) {
      commit("setPreferredCurrency", curr);
    },
    async setDeposits({ commit }, user) {
      const deposits = await firebase.getDeposits(user.uid);
      commit("setDeposits", deposits);
    },
    setLoading({ commit }, loading) {
      commit("setLoading", loading);
    },
  },
  mutations: {
    setSelectedUser(state, user) {
      state.selectedUser = user;
    },
    setUserList(state, userList) {
      state.userList = userList;
    },
    setFiltered(state, val) {
      state.filtered = val;
    },
    setSort(state, val) {
      state.sort = val;
    },
    setSortDesc(state, val) {
      state.sortDesc = val;
    },
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
    setJournal(state, journal) {
      state.journal = journal;
    },
    setPreferredCurrency(state, curr) {
      state.preferredCurrency = curr;
    },
    setFearAndGreed(state, data) {
      state.fgi = data;
    },
    setPortfolio(state, portfolio) {
      state.loading = false;
      state.portfolio = portfolio;
    },
    setDeposits(state, deposits) {
      state.deposits = deposits;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
});
