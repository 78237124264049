import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc, setDoc, getDocs, collection } from "firebase/firestore";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import moment from "moment";
import { store } from "../store";
import process from "../processing";

const firebaseConfig = {
  apiKey: "AIzaSyCyak7NQ_PH2gXEJkVKCh6iL31zvbkFYWs",
  authDomain: "jamcoin-fb93e.firebaseapp.com",
  projectId: "jamcoin-fb93e",
  storageBucket: "jamcoin-fb93e.appspot.com",
  messagingSenderId: "219576667796",
  appId: "1:219576667796:web:133160191e720457256e81",
  measurementId: "G-2CKHLD4LHC",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const db = getFirestore();

const getCurrentUser = async () => {
  const result = await FirebaseAuthentication.getCurrentUser();
  if (result.user) {
    const docRef = doc(db, "users", result.user.uid);
    const docSnap = await getDoc(docRef);
    const user = { ...result.user, ...docSnap.data() };
    return user;
  } else {
    return null;
  }
};

const getIdToken = async () => {
  const result = await FirebaseAuthentication.getIdToken();
  return result.token;
};

const setLanguageCode = async () => {
  await FirebaseAuthentication.setLanguageCode({ languageCode: "en-US" });
};

const signInWithGoogle = async () => {
  const result = await FirebaseAuthentication.signInWithGoogle();
  return result;
};

const signOut = async () => {
  await FirebaseAuthentication.signOut();
};

const useAppLanguage = async () => {
  await FirebaseAuthentication.useAppLanguage();
};

const getUsers = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));
  const users = [];
  querySnapshot.forEach((doc) => {
    const user = {
      uid: doc.id,
      investorCode: doc.data().investorCode,
      admin: doc.data().admin || false,
    };
    users.push(user);
  });

  return users;
};

const keepers = [
  "BTC",
  "ETH",
  "MATIC",
  "RENDER",
  "RNDR",
  "ORAI",
  "FET",
  "PENDLE",
  "AKT",
];

const filterObjectByKeys = (obj, keysArray) => {
  return Object.keys(obj)
    .filter((key) => keysArray.includes(key))
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
};

const getPortfolio = async (uid) => {
  const docRef = doc(db, "portfolios", uid);
  const docSnap = await getDoc(docRef);
  const portfolioData = docSnap.data();
  console.log(portfolioData);
  // Clean up data
  portfolioData.cryptos = portfolioData.cryptos.filter((crypto) =>
    keepers.includes(crypto.symbol)
  );
  portfolioData.holdings.crypto = filterObjectByKeys(
    portfolioData.holdings.crypto,
    keepers
  );
  portfolioData.jamcoin.yesterday.cryptos =
    portfolioData.jamcoin.yesterday.cryptos.filter((crypto) =>
      keepers.includes(crypto.symbol)
    );
  portfolioData.jamcoin.yesterday.holdings.crypto = filterObjectByKeys(
    portfolioData.jamcoin.yesterday.holdings.crypto,
    keepers
  );
  const portfolio = await process.processPortfolio(portfolioData);

  return portfolio;
};

const getInvestments = async (uid) => {
  let investments;
  if (uid === "total") {
    investments = {};
    const querySnapshot = await getDocs(collection(db, "investments"));
    querySnapshot.forEach((doc) => {
      investments[doc.id] = doc.data();
    });
  } else {
    const docRef = doc(db, "investments", uid);
    const docSnap = await getDoc(docRef);
    investments = docSnap.data();
  }
  return investments;
};

const getDeposits = async (uid) => {
  let deposits = [];
  const investments = await getInvestments(uid);
  if (uid === "total") {
    for (const uid of Object.keys(investments)) {
      const items = investments[uid].deposits;
      deposits.push(...items);
    }
  } else {
    deposits = investments.deposits;
  }
  // Sort the investments by date
  deposits.sort((a, b) => moment(a.date) - moment(b.date));
  // Create total field
  let total = 0;

  for (const deposit of deposits) {
    total += deposit.amount;
    deposit.amount = deposit.amount.toFixed(2);
    deposit.total = total.toFixed(2);
  }
  return deposits;
};

const saveDeposit = async (deposit) => {
  deposit.amount = parseInt(deposit.amount);
  if (store.state.currentUser.admin) {
    const investments = await getInvestments(deposit.userId);
    investments.deposits.push(deposit);
    const result = await setDoc(
      doc(db, "investments", deposit.userId),
      investments
    );
    return result;
  } else {
    return false;
  }
};

const getJournal = async () => {
  let journal = [];
  const querySnapshot = await getDocs(collection(db, "journals"));
  querySnapshot.forEach((doc) => {
    const entry = doc.data();
    entry.id = doc.id;
    journal.push(entry);
  });
  return journal;
};

const createOrUpdateJournalEntry = async (entry) => {
  const entryId = entry.id;
  delete entry.id;
  if (entry.id === "new") {
    const docRef = await addDoc(collection(db, "journals"), entry);
    return docRef.id;
  } else {
    const docRef = doc(db, "journals", entryId);
    const result = await setDoc(cityRef, entry, { merge: true });
    return result.id;
  }
};

export default {
  getCurrentUser,
  getIdToken,
  setLanguageCode,
  signInWithGoogle,
  signOut,
  useAppLanguage,
  FirebaseAuthentication,
  getPortfolio,
  getDeposits,
  getUsers,
  saveDeposit,
  getJournal,
  createOrUpdateJournalEntry,
};
