<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import firebase from "./firebase";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const authListener = firebase.FirebaseAuthentication.addListener(
      "authStateChange",
      async function (currentUser) {
        if (!currentUser.user) {
          // not logged in
          router.push("/login");
        } else {
          const userInfo = await firebase.getCurrentUser();
          store.dispatch("setCurrentUser", userInfo);
          let userList;
          if (userInfo.admin) {
            const journal = await firebase.getJournal();
            userList = await firebase.getUsers();
            const initialUser = userList.find(
              (user) => user.investorCode === "Total"
            );
            store.dispatch("setUserlist", userList);
            store.dispatch("setSelectedUser", initialUser);
            store.dispatch("setFiltered", true);
            store.dispatch("setPortfolio", initialUser);
            store.dispatch("setDeposits", initialUser);
            store.dispatch("setJournal", journal);
          } else {
            store.dispatch("setPortfolio", userInfo);
            store.dispatch("setDeposits", userInfo);
          }
        }
      }
    );
  },
});
</script>
