<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/portfolio/summary">
          <ion-icon :icon="analytics" />
          <ion-label>Portfolio</ion-label>
        </ion-tab-button>
        <ion-tab-button
          v-if="!currentUser.admin"
          tab="tab2"
          href="/portfolio/deposits"
        >
          <ion-icon :icon="archive" />
          <ion-label>Deposits</ion-label>
        </ion-tab-button>
        <ion-tab-button
          v-if="currentUser.admin"
          @click="setOpen(true)"
          tab="tab3"
        >
          <ion-icon :icon="person" />
          <ion-label>{{ selectedUser.investorCode }}</ion-label>
        </ion-tab-button>
        <!-- <ion-tab-button tab="tab4" href="" @click="toggleCurrency(preferredCurrency)">
          <ion-icon :icon="logoUsd" />
          <ion-label>{{ preferredCurrency }}</ion-label>
        </ion-tab-button> -->
        <!-- <ion-tab-button tab="tab4" href="/portfolio/charts">
          <ion-icon :icon="barChart" />
          <ion-label>Charts</ion-label>
        </ion-tab-button> -->
        <ion-tab-button v-if="currentUser.admin" tab="tab5" href="/journal">
          <ion-icon :icon="document" />
          <ion-label>Journal</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <ion-modal
      v-if="currentUser.admin"
      :is-open="isOpenRef"
      css-class="my-custom-class"
      @didDismiss="setOpen(false)"
    >
      <SelectUser @dismiss="setOpen(false)"></SelectUser>
    </ion-modal>
  </ion-page>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonModal,
} from "@ionic/vue";
import {
  ellipse,
  square,
  analytics,
  person,
  archive,
  logoUsd,
  barChart,
  document,
} from "ionicons/icons";
import { useStore } from "vuex";
import SelectUser from "../components/SelectUser.vue";

export default defineComponent({
  name: "TabsTemplate",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonModal,
    SelectUser,
  },
  setup(props, ctx) {
    const store = useStore();
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);

    return {
      isOpenRef,
      setOpen,
      currentUser: computed(() => store.state.currentUser),
      selectedUser: computed(() => store.state.selectedUser),
      preferredCurrency: computed(() => store.state.preferredCurrency),
      ellipse,
      square,
      analytics,
      person,
      archive,
      logoUsd,
      barChart,
      document,
    };
  },
  methods: {},
});
</script>
