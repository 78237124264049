import { createRouter, createWebHistory } from "@ionic/vue-router";
import Tabs from "../views/Tabs.vue";
import firebase from "../firebase";

const routes = [
  {
    path: "/",
    redirect: "/portfolio/summary",
  },
  {
    path: "/login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/journal/",
    component: Tabs,
    children: [
      {
        path: "",
        component: () => import("@/views/Journal.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ":id",
        component: () => import("@/views/JournalEntry.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/portfolio/",
    component: Tabs,
    children: [
      {
        path: "",
        redirect: "/portfolio/summary",
      },
      {
        path: "summary",
        component: () => import("@/views/Portfolio.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "charts",
        component: () => import("@/views/Charts.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "deposits",
        component: () => import("@/views/Deposits.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const user = firebase.getCurrentUser();
  if (requiresAuth && !user) {
    next("login");
  } else {
    next();
  }
});

// {
//   path: 'summary',
//   component: () => import('@/views/Tab1.vue'),
//   meta: {
//     requiresAuth: true
//   }
// },

export default router;
