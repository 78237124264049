<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Select User</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div v-for="user in userList" :key="user.uid">
        <ion-item button @click="selectUser(user)">
          <ion-label>
            {{ user.investorCode }}
          </ion-label>
        </ion-item>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SelectUser",
  emits: ["dismiss"],
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
  },
  setup() {
    const store = useStore();
    return {
      userList: computed(() => store.state.userList),
      selectedUser: computed(() => store.state.selectedUser),
    };
  },
  methods: {
    selectUser(user) {
      this.$store.dispatch("setSelectedUser", user);
      this.$store.dispatch("setPortfolio", user);
      this.$store.dispatch("setDeposits", user);
      this.$emit("dismiss", true);
    },
  },
});
</script>
